var _a, _b, _c, _d, _e, _f;
import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';
import { dispatchEvent, setConfig, setContextParams, setLogConfig, SHMAPICollector, SHMLogCollector, } from '@shm/interface';
import { browser, HTML5Storage } from '@shm/interface/lib/web';
import { rpcCollector } from '@shm/interface/lib/web.app';
import { UAParser } from 'ua-parser-js';
import packageJson from '../../../package.json';
import { config } from '../../config';
import { getURLParam } from '../urlParams';
// 获取设备平台
const getDevicePlatform = () => {
    if (browser.isAndroid()) {
        return 'android';
    }
    if (browser.isIOS()) {
        return 'ios';
    }
    return 'pc';
};
// init interface config
const collectors = [];
if (config.IS_LOCAL_DEV) {
    collectors.push(new SHMLogCollector());
}
let useRPCCollector = false;
if (browser.isSHMApp() && config.SHM_APP_FAMILY.includes(config.APP)) {
    // use rpcCollector for shm app web page@shm app
    collectors.push(rpcCollector);
    useRPCCollector = true;
}
else {
    // use api collector in browser or 3rd party app web page
    // e.g. open partner phone bill page in shm app due to universal link
    collectors.push(new SHMAPICollector());
}
setConfig({
    app: config.APP,
    apiServer: config.API_SERVER,
    platform: getDevicePlatform(),
    storage: new HTML5Storage(),
    log: config.IS_LOCAL_DEV,
    appVersion: browser.getSHMAppVersion(),
    report: {
        collectors,
        // disable batch report if we are using rpc collector, app would put them into batch
        maxReportEvents: useRPCCollector ? 1 : 20,
    },
});
// Sentry init
Sentry.init({
    enabled: false,
    dsn: config.SENTRY_DSN,
    environment: config.SENTRY_ENV,
    release: packageJson.version,
    tracesSampleRate: 1,
    integrations: (integrations) => integrations
        .filter((item) => item.name !== 'GlobalHandlers' && item.name !== 'TryCatch')
        .concat(
    // disable unhandled rejection, error report
    new Sentry.Integrations.GlobalHandlers({
        onerror: true,
        onunhandledrejection: false,
    }), new Sentry.Integrations.TryCatch({
        requestAnimationFrame: false,
    }), 
    // add browser tracing
    new BrowserTracing({
        traceFetch: false,
        traceXHR: false,
    })),
});
dispatchEvent('sentryInited');
// config log behavior according to config
setLogConfig({
    console: config.IS_LOCAL_DEV,
    consoleFilter: {
        category: {
            excludes: ['api', 'event'],
        },
    },
    sentry: !config.IS_LOCAL_DEV,
    sentryFilter: {
        level: 'warn',
    },
});
// update context params according to ua info
const uaInfo = UAParser(window.navigator.userAgent);
const inviterID = getURLParam('inviter_id') || getURLParam('inviterid');
setContextParams({
    user_agent: window.navigator.userAgent,
    browser_name: ((_a = uaInfo.browser) === null || _a === void 0 ? void 0 : _a.name) || 'unknown',
    browser_version: ((_b = uaInfo.browser) === null || _b === void 0 ? void 0 : _b.version) || 'unknown',
    os: ((_c = uaInfo.os) === null || _c === void 0 ? void 0 : _c.name) || 'unknown',
    os_version: ((_d = uaInfo.os) === null || _d === void 0 ? void 0 : _d.version) || 'unknown',
    device_brand: ((_e = uaInfo.device) === null || _e === void 0 ? void 0 : _e.vendor) || 'unknown',
    device_mode: ((_f = uaInfo.device) === null || _f === void 0 ? void 0 : _f.model) || 'unknown',
    path: window.location.pathname.slice(1) + window.location.search,
    binary_version: browser.getSHMAppBinaryVersion(),
    inviter_id: Number(inviterID) || undefined,
});
