"use strict";
try {
    const isSupportWebp = !![].map &&
        document.createElement('canvas').toDataURL('image/webp').indexOf('data:image/webp') === 0;
    const htmlClassName = isSupportWebp ? 'webp' : 'no-webp';
    document.documentElement.className = document.documentElement.className
        ? `${document.documentElement.className} ${htmlClassName}`
        : htmlClassName;
}
catch (error) {
    //
}
