import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { formatError, logger } from '@shm/interface';
import { Component } from 'react';
import styles from './styles.css';
import webErrorImg from './web_error_miaoicon.png';
// eslint-disable-next-line @typescript-eslint/ban-types
export class ErrorBoundary extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            hasError: false,
            error: '',
        };
    }
    componentDidCatch(error, info) {
        logger.exception(error, 'exception:blue_screen', info);
        const { msg, stack } = formatError(error, 7, 5, true);
        this.setState({
            hasError: true,
            error: `=== error ===\n${msg}\n\n=== stack ===\n${stack}`,
        });
    }
    render() {
        if (this.state.hasError) {
            return (_jsxs("div", Object.assign({ className: styles.statusContainer }, { children: [_jsx("img", { className: styles.statusImage, src: webErrorImg, alt: "error" }), _jsx("div", Object.assign({ className: styles.statusText }, { children: "\u55B5\u545C~\u9875\u9762\u52A0\u8F7D\u5931\u8D25" })), _jsx("button", Object.assign({ className: styles.reloadButton, onClick: () => {
                            window.location.reload();
                        } }, { children: "\u91CD\u65B0\u52A0\u8F7D" }))] })));
        }
        return this.props.children;
    }
}
ErrorBoundary.displayName = 'ErrorBoundary';
