import { App } from '@shm/interface/lib/common/app';
import jlyhFooterImage from '../assets/footer-img-jlyh.png';
import mflFooterImage from '../assets/footer-img-mfl.png';
import mqgFooterImage from '../assets/footer-img-mqg.png';
import mthFooterImage from '../assets/footer-img-mth.png';
import mxbFooterImage from '../assets/footer-img-mxb.png';
import myhFooterImage from '../assets/footer-img-myh.png';
import shmFooterImage from '../assets/footer-img-shm.png';
import jlyhLogo from '../assets/logo-jlyh.png';
import mflLogo from '../assets/logo-mfl.png';
import mqgLogo from '../assets/logo-mqg.png';
import mthLogo from '../assets/logo-mth.png';
import mxbLogo from '../assets/logo-mxb.png';
import myhLogo from '../assets/logo-myh.png';
import shmLogo from '../assets/logo-shm.png';
function appRegistry(value) {
    return value;
}
export const useAppInfo = () => {
    return appRegistry({
        shm: {
            logo: shmLogo,
            footerImage: shmFooterImage,
            app: App.APP_SHIHUIMIAO,
        },
        mqg: {
            logo: mqgLogo,
            footerImage: mqgFooterImage,
            app: App.APP_MIAOQIANGUAN,
        },
        mth: {
            logo: mthLogo,
            footerImage: mthFooterImage,
            app: App.APP_MIAOTEHUI,
        },
        mfl: {
            logo: mflLogo,
            footerImage: mflFooterImage,
            app: App.APP_MIAOFULI,
        },
        myh: {
            logo: myhLogo,
            footerImage: myhFooterImage,
            app: App.APP_MIAOYOUHUI,
        },
        mxb: {
            logo: mxbLogo,
            footerImage: mxbFooterImage,
            app: App.APP_MIAOXIAOBAO,
        },
        jlyh: {
            logo: jlyhLogo,
            footerImage: jlyhFooterImage,
            app: App.APP_PREPARE_01,
        },
    });
};
