function companyRegistry(value) {
    return value;
}
export const useCompany = () => {
    return companyRegistry({
        moshuang: {
            companyName: '上海莫双网络科技有限公司',
            companyAddress: '上海市嘉定区真南路4268号2幢J3881室',
            licenseNumber: '沪B2-20190101',
            gongwanganbeiNumber: '沪公网安备31011502014056',
            wenwangwenNumber: '沪网文(2021) 1598-120号',
            phoneNumber: '020-61114662',
        },
        xiangchen: {
            companyName: '上海香晨信息科技有限公司',
            companyAddress: '上海市嘉定区顺达路938号2幢2层215室',
            licenseNumber: '沪B2-20201642',
            gongwanganbeiNumber: '沪公网安备31011502014056',
            wenwangwenNumber: '沪网文(2020) 3652-265号',
            phoneNumber: '020-61114662',
        },
        xihu: {
            companyName: '上海喜狐文化传播有限公司',
            companyAddress: '上海市市辖区浦东新区懿德路519号',
            licenseNumber: '沪B2-20180335',
            gongwanganbeiNumber: '沪公网安备31011502014056',
            wenwangwenNumber: '沪网文(2021) 1777-152号',
            phoneNumber: '020-61114662',
        },
        youxi: {
            companyName: '上海游曦信息科技有限公司',
            companyAddress: '上海市嘉定区真南路4268号2幢J2806室',
            licenseNumber: '沪B2-20180333',
            gongwanganbeiNumber: '沪公网安备31011502014056',
            wenwangwenNumber: '沪网文(2021) 1598-120号',
            phoneNumber: '020-61114662',
        },
        zhuntianqi: {
            companyName: '上海萌鱼网络科技有限公司',
            companyAddress: '上海市嘉定区真南路4268号2幢JT6653室',
            licenseNumber: '沪B2-20201642',
            gongwanganbeiNumber: '沪公网安备31011502014056',
            wenwangwenNumber: '沪网文(2020) 3652-265号',
            phoneNumber: '020-61114662',
        },
        ybjl: {
            companyName: '上海摇笔即来健康科技有限公司',
            companyAddress: '上海市嘉定区真南路4268号2幢JT16489室',
            licenseNumber: '',
            gongwanganbeiNumber: '沪公网安备31011502014056',
            wenwangwenNumber: '',
            phoneNumber: '020-61114662',
        },
        clsw: {
            companyName: '芜湖创凌仕微网络科技有限公司',
            companyAddress: '芜湖市湾沚区安徽新芜经济开发区科创中心6楼',
            licenseNumber: '',
            gongwanganbeiNumber: '',
            wenwangwenNumber: '',
            phoneNumber: '',
        },
        twb: {
            companyName: '芜湖泰维博网络科技有限公司',
            companyAddress: '安徽省芜湖市湾沚区安徽新芜经济开发区科创中心6楼',
            licenseNumber: '',
            gongwanganbeiNumber: '',
            wenwangwenNumber: '',
            phoneNumber: '',
        },
        zyn: {
            companyName: '芜湖智亿诺网络科技有限公司',
            companyAddress: '安徽省芜湖市湾沚区安徽新芜经济开发区科创中心6楼',
            licenseNumber: '',
            gongwanganbeiNumber: '',
            wenwangwenNumber: '',
            phoneNumber: '',
        },
        temp: {
            companyName: '上海莫双网络科技有限公司',
            companyAddress: '上海市嘉定区真南路4268号2幢J3881室',
            licenseNumber: '沪B2-20190101',
            gongwanganbeiNumber: '沪公网安备31011502014056',
            wenwangwenNumber: '沪网文(2021) 1598-120号',
            phoneNumber: '13671902558',
        },
        jiamei: {
            companyName: '湖北佳美信息科技有限公司',
            companyAddress: '湖北省黄冈市罗田县凤山镇万密斋大道书香尚城3-107-207',
            licenseNumber: '',
            gongwanganbeiNumber: '',
            wenwangwenNumber: '',
            phoneNumber: '',
        },
        yazhi: {
            companyName: '湖北雅智信息科技有限公司',
            companyAddress: '湖北省黄冈市罗田县匡河镇沿河大道匡河社区202',
            licenseNumber: '',
            gongwanganbeiNumber: '',
            wenwangwenNumber: '',
            phoneNumber: '',
        },
        dingce: {
            companyName: '湖北鼎测网络科技有限公司',
            companyAddress: '湖北省黄冈市红安县杏花大道东城国际13栋一单元801',
            licenseNumber: '',
            gongwanganbeiNumber: '',
            wenwangwenNumber: '',
            phoneNumber: '',
        },
        xiangmei: {
            companyName: '湖北湘美网络科技有限公司',
            companyAddress: '湖北省黄冈市罗田县匡河镇沿河大道匡河社区201',
            licenseNumber: '',
            gongwanganbeiNumber: '',
            wenwangwenNumber: '',
            phoneNumber: '',
        },
        kaiya: {
            companyName: '杭州凯亚网络科技有限公司',
            companyAddress: '浙江省杭州市滨江区长河街道滨文路200号1幢5层5865室',
            licenseNumber: '',
            gongwanganbeiNumber: '',
            wenwangwenNumber: '',
            phoneNumber: '',
        },
    });
};
