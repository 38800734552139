import { getServerTime, flushPendingEvents, setReportEventHook, reportEvent, setContextParams, } from '@shm/interface';
import { ReportGeneralH5ScreenView } from '@shm/interface/lib/reportevents/frontend/general';
import { isUndefined, omitBy } from 'lodash-es';
import { afterUserInfoReady } from './auth';
import { onUserNavigation } from './launch';
let userNavigated = false;
let pendingEventQueue = [];
setReportEventHook((eventID, evtParams) => {
    if (!userNavigated) {
        // put event into pending queue
        pendingEventQueue.push(Object.assign(Object.assign({}, evtParams), { event_id: eventID }));
        return null;
    }
    return [eventID, evtParams];
});
onUserNavigation.then(() => {
    userNavigated = true;
    pendingEventQueue.forEach((item) => {
        reportEvent(Object.assign(Object.assign({}, item), { event_time: getServerTime() }));
    });
    pendingEventQueue = [];
});
Promise.all([afterUserInfoReady(), onUserNavigation]).then(([user]) => {
    var _a;
    if (user) {
        // update context params
        setContextParams(omitBy({
            mid: user.ID,
            membership_status: (_a = user.membershipInfo) === null || _a === void 0 ? void 0 : _a.status,
        }, isUndefined));
    }
    ReportGeneralH5ScreenView();
    flushPendingEvents();
});
// flush pending events on page hide (leave)
// https://developer.mozilla.org/en-US/docs/Web/API/Navigator/sendBeacon#sending_analytics_at_the_end_of_a_session
document.addEventListener('visibilitychange', () => {
    if (document.visibilityState !== 'hidden') {
        return;
    }
    flushPendingEvents();
});
// Safari doesn't fire the visibilitychange event when navigating away from a document,
// so also include code to check for the pagehide event (which does fire for that case in all current browsers).
document.addEventListener('pagehide', () => {
    flushPendingEvents();
});
