import { jsx as _jsx } from "react/jsx-runtime";
import { nullFunction } from '@shm-open/utilities';
import { logger } from '@shm/interface';
import { GetMyInfoV3 } from '@shm/interface/lib/user/service';
import { browser } from '@shm/interface/lib/web';
import { rpc } from '@shm/interface/lib/web.app';
import { createContext, useCallback, useEffect, useState } from 'react';
import { Hud } from '../../components/Hud';
import { afterUserInfoReady } from './auth';
import { useErrorHandler } from './useErrorHandler';
export const UserInfoContext = createContext(undefined);
export const RefreshUserInfoContext = createContext(nullFunction);
const logCategory = 'platform:userinfo';
export const UserInfoProvider = ({ children }) => {
    const [userInfo, setUserInfo] = useState();
    const errorHandler = useErrorHandler();
    useEffect(() => {
        afterUserInfoReady()
            .then((data) => {
            if (!data) {
                logger.info('user info init null', logCategory);
                return;
            }
            logger.info(`user info init: ${data.ID}`, logCategory);
            setUserInfo(data);
        })
            .catch((err) => {
            logger.info('user info init failed', logCategory);
            Hud.Text({
                text: (err === null || err === void 0 ? void 0 : err.message) || '授权失败',
                duration: 2000,
            });
            errorHandler(err);
        });
        const onUserInfoChange = (evt) => {
            var _a;
            logger.info(`user info updated from app: ${(_a = evt.userInfo) === null || _a === void 0 ? void 0 : _a.ID}`, logCategory);
            setUserInfo(evt.userInfo);
        };
        if (browser.isSHMApp()) {
            rpc.addEventListener('userInfoUpdate', onUserInfoChange);
        }
        return () => {
            if (browser.isSHMApp()) {
                rpc.removeEventListener('userInfoUpdate', onUserInfoChange);
            }
        };
    }, [errorHandler]);
    const refreshUserInfo = useCallback(() => {
        if (browser.isSHMApp()) {
            logger.info('request user update by app', logCategory);
            rpc.dispatchWebEvent({ type: 'userInfoUpdateRequest' });
            return;
        }
        logger.info('user update by api', logCategory);
        GetMyInfoV3({}).then((data) => {
            setUserInfo(data);
        });
    }, []);
    return (_jsx(UserInfoContext.Provider, Object.assign({ value: userInfo }, { children: _jsx(RefreshUserInfoContext.Provider, Object.assign({ value: refreshUserInfo }, { children: children })) })));
};
UserInfoProvider.displayName = 'UserInfoProvider';
