function hostRegistry(value) {
    return value;
}
export const useHost = () => {
    return hostRegistry({
        miaofuli_cn: {
            domain: 'miaofuli.cn',
            icpNumber: '沪ICP备18036252号-15',
        },
        miaoqianguan_com: {
            domain: 'miaoqianguan.com',
            icpNumber: '沪ICP备19029488号-2',
        },
        miaoxiaobao_cn: {
            domain: 'miaoxiaobao.cn',
            icpNumber: '沪ICP备19029488号-5',
        },
        miaoxiaobao_net: {
            domain: 'yxer.net',
            icpNumber: '沪ICP备17038103号-5',
        },
        miaoxiaobao_zhuntianqi: {
            domain: 'zhuntianqi.cn',
            icpNumber: '沪ICP备18038962号-4',
        },
        mtehui_cn: {
            domain: 'mtehui.cn',
            icpNumber: '沪ICP备18036252号-10',
        },
        mtehui_net: {
            domain: 'mtehui.net',
            icpNumber: '沪ICP备18036252号-14',
        },
        myouhui_cn: {
            domain: 'myouhui.cn',
            icpNumber: '沪ICP备19029488号-3',
        },
        myouhui_net: {
            domain: 'myouhui.net',
            icpNumber: '沪ICP备19029488号-4',
        },
        shihuimiao_com: {
            domain: '51shihuimiao.com',
            icpNumber: '沪ICP备17045642号-11',
        },
        shihuimiao01_cn: {
            domain: 'shihuimiao01.cn',
            icpNumber: '沪ICP备18036252号-4',
        },
        shmer_cn: {
            domain: 'shmer.cn',
            icpNumber: '沪ICP备18036252号-10',
        },
        shmer_net: {
            domain: 'shmer.net',
            icpNumber: '沪ICP备18036252号-13',
        },
        shmiao_net: {
            domain: 'shmiao.net',
            icpNumber: '沪ICP备17045642号-12',
        },
        wondershm_com: {
            domain: 'wondershm.com',
            icpNumber: '沪ICP备18036252号-12',
        },
        shihuilife_cn: {
            domain: 'shihuilife.cn',
            icpNumber: '沪ICP备17045642号-6',
        },
        shihuilife_net: {
            domain: 'shihuilife.net',
            icpNumber: '沪ICP备17045642号-7',
        },
        shihuimiao_net: {
            domain: 'shihuimiao.net',
            icpNumber: '沪ICP备17045642号-10',
        },
        yaobijilai_com: {
            domain: 'yaobijilai.com',
            icpNumber: '沪ICP备2022026314号-1',
        },
        lingshiwei_com: {
            domain: 'lingshiwei.com',
            icpNumber: '皖ICP备2022013731号-1',
        },
        taiweibo_com: {
            domain: 'taiweibo.com',
            icpNumber: '皖ICP备2022017039号-1',
        },
        taiweibo01_cn: {
            domain: 'taiweibo01.cn',
            icpNumber: '皖ICP备2022017039号-6',
        },
        taiweibo02_cn: {
            domain: 'taiweibo02.cn',
            icpNumber: '皖ICP备2022017039号-2',
        },
        taiweibo03_cn: {
            domain: 'taiweibo03.cn',
            icpNumber: '皖ICP备2022017039号-3',
        },
        taiweibo04_cn: {
            domain: 'taiweibo04.cn',
            icpNumber: '皖ICP备2022017039号-4',
        },
        taiweibo05_cn: {
            domain: 'taiweibo05.cn',
            icpNumber: '皖ICP备2022017039号-5',
        },
        zhiyinuo_cn: {
            domain: 'zhiyinuo.cn',
            icpNumber: '皖ICP备2022017236号-1',
        },
        zhiyinuo01_cn: {
            domain: 'zhiyinuo01.cn',
            icpNumber: '皖ICP备2022017236号-6',
        },
        zhiyinuo02_cn: {
            domain: 'zhiyinuo02.cn',
            icpNumber: '皖ICP备2022017236号-2',
        },
        zhiyinuo03_cn: {
            domain: 'zhiyinuo03.cn',
            icpNumber: '皖ICP备2022017236号-3',
        },
        zhiyinuo04_cn: {
            domain: 'zhiyinuo04.cn',
            icpNumber: '皖ICP备2022017236号-4',
        },
        zhiyinuo05_cn: {
            domain: 'zhiyinuo05.cn',
            icpNumber: '皖ICP备2022017236号-5',
        },
        lingshiwei01_cn: {
            domain: 'lingshiwei01.cn',
            icpNumber: '皖ICP备2022013731号-6',
        },
        lingshiwei02_cn: {
            domain: 'lingshiwei02.cn',
            icpNumber: '皖ICP备2022013731号-2',
        },
        lingshiwei03_cn: {
            domain: 'lingshiwei03.cn',
            icpNumber: '皖ICP备2022013731号-3',
        },
        lingshiwei04_cn: {
            domain: 'lingshiwei04.cn',
            icpNumber: '皖ICP备2022013731号-4',
        },
        lingshiwei05_cn: {
            domain: 'lingshiwei05.cn',
            icpNumber: '皖ICP备2022013731号-5',
        },
        jiameix_com: {
            domain: 'jiameix.com',
            icpNumber: '鄂ICP备2023009121号-1',
        },
        yazhix_com: {
            domain: 'yazhix.com',
            icpNumber: '鄂ICP备2023009587号-1',
        },
        dingcex_com: {
            domain: 'dingcex.com',
            icpNumber: '鄂ICP备2023010067号-1',
        },
        xiangmeix_com: {
            domain: 'xiangmeix.com',
            icpNumber: '鄂ICP备2023010081号-1',
        },
        kaiyax_com: {
            domain: 'kaiyax.com',
            icpNumber: '浙ICP备2023018208号-1',
        },
    });
};
