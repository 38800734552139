// detect launch status and report launch performance
import { decodeURLParams } from '@shm-open/utilities';
import { wait } from 'wait-ready';
const { afterReady, setReady } = wait();
/**
 * user navigated
 */
export const onUserNavigation = afterReady();
const getUserClickTime = () => {
    const hashParams = decodeURLParams(window.location.hash);
    return Number(hashParams.click_timestamp);
};
const onHashChange = () => {
    const userClickTime = getUserClickTime();
    if (userClickTime === 0) {
        return;
    }
    setReady();
    window.removeEventListener('hashchange', onHashChange);
};
window.addEventListener('hashchange', onHashChange);
// execute it once to check, since hash may never change if not load in shm app
onHashChange();
