import { addListener, logger, LoginV2, setConfig, setContextParams } from '@shm/interface';
import { App } from '@shm/interface/lib/common/app';
import { GetMyInfoV3 } from '@shm/interface/lib/user/service';
import { GetTokenByAuthCode } from '@shm/interface/lib/user/token_service';
import { browser, html5JSONStorage } from '@shm/interface/lib/web';
import { rpc, rpcCache, rpcCrypter } from '@shm/interface/lib/web.app';
import { wait } from 'wait-ready';
import { config } from '../../config';
import './setup';
const { afterAuthReady, setAuthReady } = wait('Auth');
const { afterUserInfoReady, setUserInfoReady } = wait('UserInfo');
export { afterAuthReady, afterUserInfoReady };
const cacheStorageKey = 'LOGIN_INFO';
// has miao token / auth code in params
let canRetryAuth = false;
addListener('tokenInvalid', () => {
    html5JSONStorage.removeItem(cacheStorageKey);
    if (canRetryAuth) {
        window.location.reload();
    }
});
/**
 * update user info
 * @param userInfo
 */
function updateUserInfo(userInfo) {
    if (userInfo === null || userInfo === void 0 ? void 0 : userInfo.membershipInfo) {
        setUserInfoReady(userInfo);
        return;
    }
    GetMyInfoV3({}).then((res) => {
        setUserInfoReady(res);
    });
}
/**
 * @function shmAppAuth 实惠喵app webview用户授权
 */
function shmAppAuth() {
    rpc.getCommonInfoV2().then(({ token, tk, sessionID, userInfo }) => {
        setConfig({
            token,
            tk,
            sessionID,
            crypter: rpcCrypter,
            cache: rpcCache,
        });
        setAuthReady(token);
        updateUserInfo(userInfo);
    });
}
/**
 * 使用喵口令登录
 * @returns
 */
function miaoTokenAuth() {
    const miaoToken = config.URL_PARAMS.miao_token;
    const channel = config.URL_PARAMS.channel;
    // 喵口令是否已经使用
    html5JSONStorage
        .getItem(cacheStorageKey)
        .then((res) => {
        // 本次喵口令和之前的口令不一致的话，需要重新登录
        const useCache = !miaoToken || (res === null || res === void 0 ? void 0 : res.code) === miaoToken;
        if ((res === null || res === void 0 ? void 0 : res.token) && useCache) {
            return res.token;
        }
        if (!channel || !miaoToken) {
            throw new Error('渠道或口令不能为空');
        }
        return LoginV2({
            app: config.APP,
            channel,
            miaoToken,
        }).then(({ token }) => {
            html5JSONStorage.setItem(cacheStorageKey, {
                token,
                code: miaoToken,
            });
            return token;
        });
    })
        .then((token) => {
        setConfig({ token });
        setContextParams({
            shm_app_channel: channel,
        });
        setAuthReady(token);
        updateUserInfo();
    });
}
function outerSiteOrDevAuth() {
    const token = window.localStorage.getItem('outer-site-token') ||
        window.localStorage.getItem('dev-token') ||
        '';
    if (!token) {
        logger.warn(`Token is empty, please set dev-token in localstorage, its being with 9527-{userID} or 9528-{phone number}`, 'auth');
    }
    setConfig({ token });
    setAuthReady(token);
    if (token) {
        updateUserInfo();
    }
    else {
        // allows no user info
        setUserInfoReady();
    }
}
/**
 * 微信授权
 * @returns
 */
function cDomainWechatAuth(authCode) {
    canRetryAuth = true;
    // try load token
    html5JSONStorage
        .getItem(cacheStorageKey)
        .then((storageToken) => {
        if (storageToken === null || storageToken === void 0 ? void 0 : storageToken.token) {
            return storageToken === null || storageToken === void 0 ? void 0 : storageToken.token;
        }
        return GetTokenByAuthCode({ code: authCode }).then(({ token }) => {
            canRetryAuth = false; // code already used
            html5JSONStorage.setItem(cacheStorageKey, {
                token,
            });
            return token;
        });
    })
        .then((token) => {
        setConfig({ token });
        const channel = config.URL_PARAMS.channel;
        setContextParams({
            shm_app_channel: channel,
            shm_app_account: 'neilaxin',
        });
        setAuthReady(token);
        updateUserInfo();
    });
}
const auth = () => {
    // not sure why but it seems qtt has a special case here to skip auth
    if (browser.isQutoutiaoApp() && config.URL_PARAMS.from === 'qtt_gold_mall') {
        setAuthReady();
        setUserInfoReady();
        return;
    }
    // 实惠喵app登录
    if (browser.isSHMApp() && config.SHM_APP_FAMILY.includes(config.APP)) {
        shmAppAuth();
        return;
    }
    // 微信登录
    if (browser.isWechat() && config.URL_PARAMS.auth_code) {
        cDomainWechatAuth(config.URL_PARAMS.auth_code);
        return;
    }
    // 第三方使用喵口令登录
    if (!config.SHM_APP_FAMILY.concat(App.APP_UNDEFINED).includes(config.APP) &&
        config.URL_PARAMS.miao_token) {
        miaoTokenAuth();
        return;
    }
    outerSiteOrDevAuth();
};
auth();
