import { nullFunction } from '@shm-open/utilities';
import { logger } from '@shm/interface';
import { App } from '@shm/interface/lib/common/app';
import { browser } from '@shm/interface/lib/web';
import { config } from '../../config';
// polyfill methods to avoid crashes
if (config.APP === App.APP_QUTOUTIAO || config.APP === App.APP_MIDU) {
    // eslint-disable-next-line no-underscore-dangle
    if (!window._handleMessageFromNative) {
        // QTT环境下Native会注入此方法，有时候会注入不成功，为了防止页面崩溃由前端注册一个空方法
        // eslint-disable-next-line no-underscore-dangle
        window._handleMessageFromNative = nullFunction;
    }
}
if (config.APP === App.APP_QUTOUTIAO) {
    if (!window.qapp_monitor) {
        // QTT环境下可能未注册成功的对象，为了防止页面崩溃由前端注册一个空对象
        window.qapp_monitor = {};
    }
    if (!window.qapp_monitor.putPerfTiming) {
        // QTT环境下可能未注册成功的对象，为了防止页面崩溃由前端注册一个空方法
        window.qapp_monitor.putPerfTiming = nullFunction;
    }
}
// https://sentry.shmiao.net/organizations/sentry/issues/465272/?project=5
// xianwan
if (config.APP === App.APP_XIANWAN && !window.setProgress) {
    window.setProgress = nullFunction;
}
// https://sentry.shmiao.net/organizations/sentry/issues/460321/?project=5
// qujianpan
if ((config.APP === App.APP_QUJIANPAN || config.APP === App.APP_QUJIANPAN_SPEED) &&
    !window.refreshCallBack) {
    window.refreshCallBack = nullFunction;
}
// https://sentry.shmiao.net/organizations/sentry/issues/456692/
// midu
if (config.APP === App.APP_MIDU) {
    if (!window.dscb0)
        window.dscb0 = nullFunction;
    if (!window.dscb1)
        window.dscb1 = nullFunction;
    if (!window.dscb2)
        window.dscb2 = nullFunction;
    if (!window.dscb3)
        window.dscb3 = nullFunction;
    if (!window.dscb4)
        window.dscb4 = nullFunction;
}
// forbid eval
// https://sentry.shmiao.net/organizations/sentry/issues/471238/
// https://sentry.shmiao.net/organizations/sentry/issues/456440/
// someone injects code into the page using eval (like below)
// https://github.com/beautify-web/js-beautify/issues/1111
// eslint-disable-next-line no-eval
if (config.APP !== App.APP_XIANWAN && // xianwan do eval
    !(browser.isWechat() && browser.isIOS()) // ios wechat do eval
) {
    // eslint-disable-next-line no-eval
    window.eval = (code) => {
        logger.warn('eval is forbidden', 'forbid:eval', {
            code,
            stack: new Error().stack,
        });
    };
}
