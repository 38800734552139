import { useState } from 'react';
/**
 * triggers Error boundary with given error
 * @returns error handler
 */
export const useErrorHandler = () => {
    const [error, setError] = useState(null);
    if (error) {
        throw error;
    }
    return setError;
};
