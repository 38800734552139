import { jsx as _jsx } from "react/jsx-runtime";
// css
import '../../assets/css/global.css';
// modules
import './polyfill';
import './webp';
import './setup';
import './launch';
import './report';
import { ComposeProviders } from '@shm-open/utilities/lib/react';
import { AuthStateProvider } from './AuthStateProvider';
import { ErrorBoundary } from './ErrorBoundary';
import { UserInfoProvider } from './UserInfoProvider';
/**
 * basic platform component, provide auth/userinfo/device info and etc
 */
export const Platform = ({ children, providers = [] }) => {
    return (_jsx(ComposeProviders, Object.assign({ providers: [ErrorBoundary, AuthStateProvider, UserInfoProvider, ...providers] }, { children: children })));
};
Platform.displayName = 'Platform';
