import { getDomainName } from '@shm-open/utilities';
import { useCallback, useMemo } from 'react';
import { config } from '../config';
import { useAppInfo } from './useAppInfo';
import { useCompany } from './useCompany';
import { useHost } from './useHost';
import { useInformantCenter } from './useInformantCenter';
export var FindType;
(function (FindType) {
    FindType[FindType["FIND_BY_DOMAIN"] = 0] = "FIND_BY_DOMAIN";
    FindType[FindType["FIND_BY_APP"] = 1] = "FIND_BY_APP";
})(FindType || (FindType = {}));
export const useCurrentCompanyInfo = () => {
    const host = useHost();
    const appInfo = useAppInfo();
    const company = useCompany();
    const informantCenter = useInformantCenter();
    /**
     * order matters
     */
    const infoList = useMemo(() => {
        return [
            // main domains
            [host.shmer_net, appInfo.shm, company.moshuang, informantCenter.shanghai],
            [host.shmiao_net, appInfo.shm, company.xihu, informantCenter.shanghai],
            [host.miaoqianguan_com, appInfo.mqg, company.xiangchen, informantCenter.shanghai],
            [host.mtehui_cn, appInfo.mth, company.moshuang, informantCenter.shanghai],
            [host.miaofuli_cn, appInfo.mfl, company.moshuang, informantCenter.shanghai],
            [host.myouhui_cn, appInfo.myh, company.xiangchen, informantCenter.shanghai],
            [host.miaoxiaobao_cn, appInfo.mxb, company.xiangchen, informantCenter.shanghai],
            [host.miaoxiaobao_net, appInfo.mxb, company.youxi, informantCenter.shanghai],
            [
                host.miaoxiaobao_zhuntianqi,
                appInfo.mxb,
                company.zhuntianqi,
                informantCenter.shanghai,
            ],
            // backup domains
            [host.myouhui_net, appInfo.myh, company.xiangchen, informantCenter.shanghai],
            [host.mtehui_net, appInfo.mth, company.moshuang, informantCenter.shanghai],
            // temp or legacy domains
            [host.shihuimiao_com, appInfo.shm, company.xihu, informantCenter.shanghai],
            [host.shihuimiao01_cn, appInfo.shm, company.temp, informantCenter.shanghai],
            [host.shmer_cn, appInfo.mth, company.moshuang, informantCenter.shanghai],
            [host.wondershm_com, appInfo.mfl, company.moshuang, informantCenter.shanghai],
            [host.shihuilife_cn, appInfo.myh, company.xihu, informantCenter.shanghai],
            [host.shihuilife_net, appInfo.myh, company.xihu, informantCenter.shanghai],
            [host.shihuimiao_net, appInfo.myh, company.xihu, informantCenter.shanghai],
            [host.yaobijilai_com, appInfo.jlyh, company.ybjl, informantCenter.shanghai],
            [host.lingshiwei_com, appInfo.shm, company.clsw, informantCenter.jiangsu],
            [host.taiweibo_com, appInfo.shm, company.twb, informantCenter.jiangsu],
            [host.taiweibo01_cn, appInfo.shm, company.twb, informantCenter.jiangsu],
            [host.taiweibo02_cn, appInfo.shm, company.twb, informantCenter.jiangsu],
            [host.taiweibo03_cn, appInfo.shm, company.twb, informantCenter.jiangsu],
            [host.taiweibo04_cn, appInfo.shm, company.twb, informantCenter.jiangsu],
            [host.taiweibo05_cn, appInfo.shm, company.twb, informantCenter.jiangsu],
            [host.zhiyinuo_cn, appInfo.shm, company.zyn, informantCenter.jiangsu],
            [host.zhiyinuo01_cn, appInfo.shm, company.zyn, informantCenter.jiangsu],
            [host.zhiyinuo02_cn, appInfo.shm, company.zyn, informantCenter.jiangsu],
            [host.zhiyinuo03_cn, appInfo.shm, company.zyn, informantCenter.jiangsu],
            [host.zhiyinuo04_cn, appInfo.shm, company.zyn, informantCenter.jiangsu],
            [host.zhiyinuo05_cn, appInfo.shm, company.zyn, informantCenter.jiangsu],
            [host.lingshiwei01_cn, appInfo.shm, company.clsw, informantCenter.jiangsu],
            [host.lingshiwei02_cn, appInfo.shm, company.clsw, informantCenter.jiangsu],
            [host.lingshiwei03_cn, appInfo.shm, company.clsw, informantCenter.jiangsu],
            [host.lingshiwei04_cn, appInfo.shm, company.clsw, informantCenter.jiangsu],
            [host.lingshiwei05_cn, appInfo.shm, company.clsw, informantCenter.jiangsu],
            [host.jiameix_com, appInfo.shm, company.jiamei, informantCenter.jiangsu],
            [host.yazhix_com, appInfo.shm, company.yazhi, informantCenter.jiangsu],
            [host.dingcex_com, appInfo.shm, company.dingce, informantCenter.jiangsu],
            [host.xiangmeix_com, appInfo.shm, company.xiangmei, informantCenter.jiangsu],
            [host.kaiyax_com, appInfo.shm, company.kaiya, informantCenter.jiangsu],
        ];
    }, [appInfo, company, host, informantCenter]);
    return useCallback((type, app) => {
        if (type === FindType.FIND_BY_DOMAIN) {
            // search for expected info
            // - search by host
            const domain = getDomainName(window.location.hostname);
            let found = infoList.find(([hostInfo]) => hostInfo.domain === domain);
            // - search by config app
            if (!found) {
                found = infoList.find(([, a]) => a.app === config.APP);
            }
            return found !== null && found !== void 0 ? found : infoList[0];
        }
        if (type === FindType.FIND_BY_APP && app) {
            const result = infoList.find(([, a]) => a.app === app);
            return result !== null && result !== void 0 ? result : infoList[0];
        }
        return infoList[0];
    }, [infoList]);
};
