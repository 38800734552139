import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useEffect, useState } from 'react';
import { afterAuthReady } from './auth';
const defaultState = {
    ready: false,
    isLoggedIn: false,
};
export const AuthStateContext = createContext(defaultState);
export const AuthStateProvider = ({ children }) => {
    const [authState, setAuthState] = useState(defaultState);
    useEffect(() => {
        afterAuthReady().then((token) => {
            setAuthState({
                ready: true,
                isLoggedIn: !!token,
            });
        });
    }, []);
    return _jsx(AuthStateContext.Provider, Object.assign({ value: authState }, { children: children }));
};
AuthStateProvider.displayName = 'AuthStateProvider';
