function companyRegistry(value) {
    return value;
}
export const useInformantCenter = () => {
    return companyRegistry({
        shanghai: {
            name: '上海互联网举报中心',
            url: 'http://www.shjbzx.cn',
            phoneNumber: '13262583569',
        },
        jiangsu: {
            name: '江苏互联网举报中心',
            url: 'https://js12377.cn/',
            phoneNumber: '13262583569',
        },
    });
};
