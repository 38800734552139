import { getURLHashParam } from '@shm-open/utilities';
import { config } from '../config';
const paramsData = {};
const getURLParams = (key) => {
    if (key in paramsData) {
        return paramsData[key];
    }
    const v = config.URL_PARAMS[key];
    if (v) {
        paramsData[key] = v;
        return v;
    }
    // do not cache hash params
    return getURLHashParam(window.location.href, key);
};
export const getURLParam = (key) => {
    const v = getURLParams(key);
    return Array.isArray(v) ? v[0] : v;
};
export const getURLParamFrom = () => {
    return getURLParam('from') || 'unknown';
};
